<template>
    <!--START FOOTER-->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 margin-t-20">
                        <h4>Il-Bankini taċ-Ċittadini!</h4>
                        <div class="text-gray margin-t-20">
                            <ul class="list-unstyled list-inline footer-list">
                                <li v-for="navItem in navBar" :key="navItem"><a :href="'#' + navItem.link">{{ navItem.title}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!--END FOOTER-->
</template>

<script>
    import navbar_data from "../../data/nav.json";

    export default {
      name: 'FooterSection',
      data() {
          return {
              navBar: navbar_data,
          }
      },
    }
</script>
