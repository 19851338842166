<template>
    <!--START HOME-->
        <section class="section bg-home home-half scroll-select .desktop-only" id="home">
            <div class="bg-overlay-top"></div>
            <!-- <div class="display-table">
                <div class="display-table-cell">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 offset-lg-2 text-white text-center">
                                <h1 class="home-title">Il-Bankini taċ-Ċittadini!</h1>
                                <h2>Give us back our pavements!</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="bg-pattern-effect">
                <img src="images/bg-pattern.png" alt="">
            </div> -->
        </section>
        <!--END HOME-->

</template>

<script>
"use strict";
export default {
  name: 'HeroSection',
  props: {
  }
}
</script>
