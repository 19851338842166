<template>
<!-- START NAVBAR -->
        <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
            <div class="container">
                <!-- LOGO -->
                <a class="navbar-brand logo text-uppercase" href="#home">
                    Il-Bankini taċ-Ċittadini!
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="mdi mdi-menu"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav navbar-center" id="mySidenav">
                        <li v-for="item in navbar" :key="item" class="nav-item">
                            <a :href="'#' + item.link" class="nav-link">{{ item.title }}</a>
                        </li>
                    </ul>
                    <!-- <div class="nav-button ml-auto">
                        <ul class="nav navbar-nav navbar-right">
                        </ul>
                    </div> -->
                </div>
            </div>
        </nav>
         <!-- END NAVBAR -->
</template>

<script>
    import navbar_data from "../../data/nav.json";

    export default {
        name: 'Navbar',
        data() {
            return {
                navbar: navbar_data
            }
        }
    }
</script>
