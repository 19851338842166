<template>
    <div id="home1">
        
        <navbar></navbar>

        <hero-section></hero-section>

        <endorse></endorse>

        <demands></demands>

        <protest-info></protest-info>

        <share-section></share-section>

        <endorsements></endorsements>

        <contact></contact>

        <!-- <hall-of-shame></hall-of-shame> -->

        <social></social>

        <footer-section></footer-section>

    </div>
    
</template>

<script>
    "use strict";
    import Navbar from './Elements/Navbar'
    import HeroSection from './Sections/HeroSection'
    import Endorsements from './Sections/Endorsements'
    import Contact from './Sections/Contact'
    import Social from './Sections/Social'
    import FooterSection from './Elements/FooterSection'
    import Demands from "./Sections/Demands";
    // import HallOfShame from "./Sections/HallOfShame";
    import ProtestInfo from "./Sections/ProtestInfo";
    import ShareSection from "./Sections/ShareSection";
    import Endorse from "./Sections/Endorse";

    export default {
        name: 'Home',
        components: {
          Endorse,
          ShareSection,
          ProtestInfo,
          Demands,
          // HallOfShame,
          Navbar,
          HeroSection,
          Endorsements,
          Contact,
          Social,
          FooterSection
        },
    }

</script>
