<template>
    <!-- CONTACT FORM START-->
        <section class="section scroll-select" id="endorse-form">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <h1 class="section-title text-center">Register your endorsement</h1>
                        <div class="section-title-border margin-t-20"></div>
                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            Formal and informal groups (NGOs, resident associations, students, workers, etc) can <!--fill in the below form to endorse our campaign.-->
                            endorse our campaign by <a href="mailto:info@movimentgraffitti.org">sending us an email</a>,
                            including the name and logo of the organisation. The organisation details will then
                            be included in the list of participating organisations above.
                        </p>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-4">
                        <div class="mt-4 pt-4">
                            <p class="mt-4"><span class="h5">Date</span><br> <span class="text-gray d-block mt-2">27th April 2024</span></p>
                            <p class="mt-4"><span class="h5">Time</span><br> <span class="text-gray d-block mt-2">10:30 a.m.</span></p>
                            <p class="mt-4"><span class="h5">Location</span><br> <span class="text-gray d-block mt-2">Merchants Street (next to MUŻA), Valletta</span></p>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="custom-form mt-4 pt-4">
                            <div id="message"></div>
                            <form method="post" action="/php/kontact.php" name="contact-form" id="contact-form">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mt-2">
                                            <input name="name" id="name" type="text" class="form-control" placeholder="Name of Group*" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mt-2">
                                            <input name="email" id="email" type="email" class="form-control" placeholder="Email Address*" required>
                                        </div>
                                    </div>                                
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mt-2">
                                            <input name="description" id="description"  type="text" class="form-control" placeholder="Short Description of Group*" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mt-2">
                                            <input name="mobile" id="mobile"  type="text" class="form-control" placeholder="Contact Number (Optional)">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group mt-2">
                                            <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Comment..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 text-right">
                                        <input type="submit" id="submit" name="send" class="submitBnt btn btn-custom" value="Send">
                                        <div id="simple-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>  
                    </div>
                </div> -->
            </div>
        </section> 
        <!-- CONTACT FORM END-->
</template>

<script>
export default {
  name: 'Contact',
  props: {
  }
}
</script>
