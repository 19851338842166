<template>
    <!--START Endorsements-->
        <section class="section pt-4 scroll-select" id="endorsements">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <h1 class="section-title text-center">Participants</h1>
                        <div class="section-title-border margin-t-20"></div>
                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            These are the groups who are endorsing this campaign. The list is updated frequently.
                            You can register your endorsement <a href="mailto:info@movimentgraffitti.org">by sending us an email</a>.
                        </p>
                    </div>
                </div>
                <div class="row margin-t-50">
                    <div v-for="endorsement in endorsements" :key="endorsement" class="col-lg-3 col-sm-6">
                        <endorsement :name="endorsement.name" :second-name="endorsement.secondName" :image="endorsement.image" :link="endorsement.link"></endorsement>
                    </div>
                </div>
            </div>
        </section>
        <!--END Endorsements-->
</template>

<script>
"use strict";
import Endorsement from "../Elements/Endorsement";
import endorsements_data from "../../data/endorsements.json";

export default {
  name: 'Endorsements',
    components: {Endorsement},
    data() {
      return {
          endorsements: endorsements_data,
      }
    }
}
</script>
