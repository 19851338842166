<template>
    <!--START PROTEST INFO-->
        <section class="section bg-light scroll-select" id="press-conference">
            <div class="container">
                <div class="row vertical-content">
                    <div class="col-lg-5">
                        <div class="features-box">
                            <h3>Agħtuna lura Pjazza San Ġorġ!</h3>
                            <h4>Press Conference</h4>
                            <ul class="text-gray list-unstyled margin-t-30 features-item-list">
                                <li class=""> Date:  <strong>Saturday 15th June</strong></li>
                                <li class=""> Where: <strong>St George's Square, Rabat (Gozo)</strong></li>
                                <li class=""> Time:  <strong>11:00 a.m.</strong></li>
                                <!-- <li class="">What to bring: <strong>Placards</strong></li> -->
                            </ul>
                            <a href="https://www.facebook.com/events/1174653190328863" class="btn btn-custom margin-t-30 waves-effect waves-light" target="_blank">
                                Facebook Event <i class="mdi mdi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="features-img features-right text-right">
                            <img src="images/cover-photo.png" alt="il-bankini-tac-cittadini-press-conference-banner" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--END PROTEST INFO-->
</template>

<script>
export default {
  name: 'ProtestInfo',
  props: {
  }
}
</script>
