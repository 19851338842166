<template>
    <!--START DEMANDS-->
        <section class="section pt-5 scroll-select" id="demands">
            <div class="container">
          
                <h1 class="section-title text-center">Demands</h1>
                <div class="section-title-border margin-t-20"></div>

                <div class="row margin-t-30">
                    <div class="col-lg-4 margin-t-20">
                        <div class="services-box text-center hover-effect">
                            <a href='#review' style="color: #000000; text-decoration: none">
                                <i class="pe-7s-note2 text-custom"></i>
                                <h4 class="padding-t-15">Review of Legislation</h4>
                            </a>
                            <!-- <p class="padding-t-15 text-gray demand">
                                Enforce and amend the Policy for Outdoor Catering Areas; introduce new contraventions and harsh penalties for non-compliance.
                            </p> -->
                        </div>
                    </div>
                    <div class="col-lg-4 margin-t-20">
                        <div class="services-box text-center hover-effect">
                            <a href='#release' style="color: #000000; text-decoration: none">
                                <i class="pe-7s-hammer text-custom"></i>
                                <h4 class="padding-t-15">Release of Information</h4>
                            </a>
                            <!-- <p class="padding-t-15 text-gray demand">
                                Change the way that permits are granted by the respective authorities, ensuring that all information is public and transparent.
                            </p> -->
                        </div>
                    </div>
                    <div class="col-lg-4 margin-t-20">
                        <div class="services-box text-center hover-effect">
                            <a href='#rightofaction' style="color: #000000; text-decoration: none">
                                <i class="pe-7s-check text-custom"></i>
                                <h4 class="padding-t-15">Right of Action</h4>
                            </a>
                            <!-- <p class="padding-t-15 text-gray demand">
                                Have a clear right of action for residents to report illegalities and ensure that the relevant authorities act on such reports.
                            </p> -->
                        </div>
                    </div>
                </div><br><br>
                
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <img id='review' class=img_center src="images/demand_1_review.png" alt="Demand - Review of legislation">
                        <p class="section-subtitle text-gray text-left padding-t-30 font-secondary">
                            <b>Review of legislation</b><br>
                            We are demanding the revision of the Policy, Guidance and Standards for Outdoor Catering Areas on Public 
                            Open Space 2016 and the introduction of stricter standards and new legislative provisions. The policies 
                            entrenched within the law regulating outdoor catering areas in public spaces need to be seriously enforced, 
                            and with this in mind, we are suggesting new provisions and deterrents to limit abuse and harsher penalties 
                            for abusers.
                        </p><br><br>
                        <img id='release' class=img_center src="images/demand_2_release.png" alt="Demand - Release of information">
                        <p class="section-subtitle text-gray text-left padding-t-30 font-secondary">
                            <b>Release of information</b><br>
                            Land, the scarcest commodity in Malta, is being granted to private interests with an astounding lack of 
                            transparency. The Lands Authority's operations are shrouded in a veil of mystery. This state of affairs 
                            cannot be allowed to go on. Applications for encroachments for Outdoor Catering Areas to the Lands Authority 
                            should be published and made available to the public before they are considered and decided. Conditions 
                            relating to encroachment permits are to be published and also exhibited on site. Third parties should
                            have a right to object and appeal from the granting of such encroachments and for their renewal.
                        </p><br><br>
                        <img id='rightofaction' class=img_center src="images/demand_3_rightofaction.png" alt="Demand - Right of action">
                        <p class="section-subtitle text-gray text-left padding-t-30 font-secondary">
                            <b>Right of action</b><br>
                            A right of action should be introduced giving any person the right to sue for the revocation of any 
                            encroachment granted in the event that any permit conditions are breached. The right of action for
                            the revocation or declaration of nullity of a permit in respect of any encroachment where conditions
                            have been breached shall appertain indistinctly to any person without the need to show any private
                            interest in support of such an action.
                        </p>
                        <p class="section-subtitle text-gray text-center padding-t-30 font-secondary">
                            The full list of demands can be accessed <a href="/assets/il-bankini-tac-cittadini-demands-ENG.pdf" target="_blank">here</a>.<br>
                            Aqra l-proposti tagħna bil-Malti <a href="/assets/il-bankini-tac-cittadini-proposti-MLT.pdf" target="blank">hawnhekk</a>.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <!--START SERVICES-->
</template>

<script>
"use strict";
export default {
  name: 'Demands'
}
</script>
