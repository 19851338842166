<template>
    <!--START PROCESS-->
        <section class="section bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        
                        <h1 class="section-title text-center">Il-Bankini taċ-Ċittadini!</h1>
                        <h2 class="section-title text-center">Call for Participation</h2>

                        <div class="section-title-border margin-t-20"></div>

                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            Resident groups and socio-environmental NGOs have teamed up to fight against the rampant take-up of public
                            land for commercial interests.
                        </p>

                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            Public space is constantly being encroached on by commercial outlets, particularly catering establishments,
                            transforming what used to be citizens’ pavements, squares and coastlines into mazes of tables, chairs, and
                            tents. These uncontrolled and many times illegal encroachments are an unbearable daily nuisance to residents
                            who are being denied their rights to freely use public space. These spaces have also become less accessible
                            and more unsafe, resulting in reduced mobility for pedestrians, the mobility-challenged, senior citizens and
                            parents with pushchairs or prams. These encroachments also clash with historical contexts, impede traditional
                            cultural activities, and even block emergency vehicles.
                        </p>

                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            The existing policy for Outdoor Catering Areas in Public Open Spaces provides a set of guidelines and standards
                            that catering establishments are meant to follow, but these are repeatedly and blatantly ignored. Often, owners
                            of catering outlets do not even bother to obtain a permit before occupying public space. On the occasions that
                            the irregularities and abuses are brought to the attention of the authorities, such illegal behaviour often
                            ends up being rewarded with a "regularisation" or sanctioning of the encroachment. Even with the necessary 
                            permits in hand, abuses are still pervasive. The meagre fines that are handed out by the authorities are no
                            real deterrent for law abusers who still end up profiting from stolen public land.
                        </p>

                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            Efforts by residents to report breaches of the law are also exercises in futility, as residents are ping-ponged
                            from one authority to another in an attempt to make residents feel helpless. The authorities responsible for
                            this mess, namely the Planning Authority, the Lands Authority, and the Malta Tourism Authority, are shrugging
                            off responsibility and refusing to keep abusers in check. Residents from all around Malta and Gozo have
                            understandably had enough!
                        </p>

                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            All groups in Malta and Gozo, whether formal and informal, can endorse this initiative by
                            <a href="mailto:info@movimentgraffitti.org">contacting us by email</a>.<!--or filling in the <a href="#endorse-form">online form</a> on this website.-->
                            Groups can include non-governmental organisations as well as residents, students, and workers: if you
                            support the demands of our campaign, you are invited to join us.<!--<a href="#endorse-form">join us</a>.-->
                        </p>

                        <p class="section-subtitle text-gray text-center font-secondary padding-t-30">
                            The full list of demands can be accessed <a href="/assets/il-bankini-tac-cittadini-demands-ENG.pdf" target="_blank">here</a>.<br>
                            Aqra l-proposti tagħna bil-Malti <a href="/assets/il-bankini-tac-cittadini-proposti-MLT.pdf" target="blank">hawnhekk</a>.
                        </p>

                        <!-- FORM -->
                        <!-- <div align="center">
                            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe3gJoYJRloKeS-t5DytcelAItM3oP6IfdtKMmcp_PlQqycAQ/viewform?embedded=true" width="1000" height="1400" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        </div> -->
                        
                    </div>
                </div>
            </div>
        </section>
        <!--END PROCESS-->
</template>

<script>
"use strict";
export default {
  name: 'Endorse',
  props: {
  }
}
</script>
